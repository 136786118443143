import Image from 'next/image';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { primary300 } from '@constants/colors';
import { smallTablet, tablet, laptop } from '@constants/media-queries';

const SlideshowContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

const ImageContainer = styled.div<{ faded: boolean; position: string }>`
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  transition: opacity 0.5s ease;
  opacity: ${({ faded }) => (faded ? 0 : 1)};
  ${({ position }) => position && `margin-${position}: 200px;`}
  z-index: 1;
  ${smallTablet} {
    min-width: 170px;
    min-height: 170px;
  }
  ${tablet} {
    min-width: 200px;
    min-height: 200px;
  }
`;

const CircleBackground = styled.div`
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: ${primary300};
  z-index: 0;
  margin-top: 55px;
  ${tablet} {
    margin-top: 110px;
    width: 175px;
    height: 175px;
  }
  ${laptop} {
    margin-top: 75px;
    width: 250px;
    height: 250px;
  }
`;

const images = [
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-2_RBqjSQOCy.jpg?updatedAt=1715869281159&ik-s=eca04e0ca6b9cf36d412e68720138e64ad5933ca',
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-6_meyu56ojo.jpg?updatedAt=1715955193940&ik-s=17a6e41cc8588f586d305332118af09d5881db6d',
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-4_TGzT5ReTX.jpg?updatedAt=1715955193899&ik-s=366723a908b359907ccc1a1cd33a844576d2a0b4',
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-3_jzZZxjsUl.jpg?updatedAt=1715869281228&ik-s=072ed95cc82ad56b1b3e199ddfdc5119b141b8ab',
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-5_5iBSlE2V0.jpg?updatedAt=1715869281437&ik-s=74d83b11ac64951024f1c4b90de812efbeca005d',
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/homepage-header/belmondofoto-header-1_bXh5dExVJ.jpg?updatedAt=1715869281146&ik-s=1eb16ef8198c8fbe12aa3761064b8d8c289eba31',
];

const ImageSlider = () => {
  const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(3);
  const [faded1, setFaded1] = useState(false);
  const [faded2, setFaded2] = useState(false);

  useEffect(() => {
    const imageTransition = 300; // Fade 300ms
    const imageDuration = 8000; // Show image for 8s
    const delaySecondInterval = 4000; // Start after 4s

    const updateImage1 = () => {
      setFaded1(true);
      setTimeout(() => {
        setCurrentImageIndex1((prevIndex) => (prevIndex + 1) % images.length);
        setFaded1(false);
      }, imageTransition);
    };

    const updateImage2 = () => {
      setFaded2(true);
      setTimeout(() => {
        setCurrentImageIndex2((prevIndex) => (prevIndex + 1) % images.length);
        setFaded2(false);
      }, imageTransition);
    };

    const interval1 = setInterval(updateImage1, imageDuration);
    const interval2 = setTimeout(
      () => setInterval(updateImage2, imageDuration),
      delaySecondInterval,
    );

    return () => {
      clearInterval(interval1);
      clearTimeout(interval2);
    };
  }, []);

  return (
    <SlideshowContainer>
      <ImageContainer faded={faded1} position="bottom">
        <Image
          src={images[currentImageIndex1]}
          alt={`Slideshow Image ${currentImageIndex1}`}
          width={200}
          height={200}
          priority
        />
      </ImageContainer>
      <ImageContainer faded={faded2} position="top">
        <Image
          src={images[currentImageIndex2]}
          alt={`Slideshow Image ${currentImageIndex2}`}
          width={200}
          height={200}
          priority
        />
      </ImageContainer>
      <CircleBackground />
    </SlideshowContainer>
  );
};

export default ImageSlider;
