import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import type { GetServerSideProps } from 'next';
import Link from 'next/link';
import styled from 'styled-components';

import BookThemes from '@components/BookThemes';
import BookTypes from '@components/BookTypes';
import Collaborate from '@components/Collaborate';
import EditorExample from '@components/EditorExample';
import Example from '@components/Example';
import Footer from '@components/Footer';
import Gift from '@components/Gift';
import GoodToKnow from '@components/GoodToKnow';
import ImageSlider from '@components/ImageSlider';
import Navigation from '@components/Navigation';
import Promise from '@components/Promise';
import Reviews from '@components/Reviews';
import ReviewsRating from '@components/ReviewsRating';
import TwoColumn from '@components/TwoColumn';
import USP from '@components/USP';
import {
  backgroundColored,
  brand,
  cta,
  ctaOutline,
  fontDark,
} from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice, exampleBooks, productionUrl } from '@constants/routes';
import { containerWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import CustomHead from '@elements/CustomHead';
import Text from '@elements/Text';
import Title from '@elements/Title';
import { PageGetFeaturedComp, ssrGetFeatured } from '@graphql/generated/page';
import useMediaQuery from '@hooks/useMediaQuery';

const StyledMain = styled.main`
  background-color: ${backgroundColored};
`;

const Wrapper = styled.div`
  max-width: ${containerWidth};
  margin: 0 auto;
`;

const Content = styled.div`
  color: ${fontDark};
  width: 100%;
  justify-content: center;

  > * {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    ${tablet} {
      justify-content: left;
      text-align: left;
    }
  }

  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const StyledTitle = styled(Title)`
  color: ${brand};

  @media screen and (min-width: 1272px) {
    font-size: 72px;
    line-height: 84px;
  }
`;

const StyledText = styled(Text)`
  max-width: 365px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${space * 3}px;
  ${tablet} {
    margin-bottom: ${space * 3}px;
  }
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;
  &:hover {
    color: ${ctaOutline};
  }
`;

const Home: PageGetFeaturedComp = () => {
  const { data } = ssrGetFeatured.usePage();
  const { isMobile } = useMediaQuery();

  return (
    <>
      <CustomHead
        title="Maak samen een fotoboek. Voor jezelf of als cadeau"
        description="Met Belmondofoto maak je alleen of samen, eenvoudig je eigen fotoboek als cadeau voor je vriend(in), je familie of je collega. Zo leg  je in een handomdraai blijvende herinneringen vast."
        canonicalUrl={productionUrl}
      />
      <Navigation backgroundColor={backgroundColored} />
      <StyledMain>
        <Wrapper>
          <TwoColumn
            alignment="center"
            paddingBotMobile={0}
            leftColumn={
              <Content>
                <ReviewsRating />
                <StyledTitle size="xxl" as="h1" className="mt-8">
                  De mooiste
                  <br />
                  herinneringen
                  <br />
                  maak je samen
                </StyledTitle>
                <StyledText className="mt-24" weight={400}>
                  Bewaar ze met elkaar in het enige fotoboek dat je écht samen
                  maakt
                </StyledText>
                <Actions className="mt-32">
                  <Link href={bookChoice} passHref>
                    <a>
                      <Button iconRight={faArrowRightLong} size="big">
                        Begin nu
                      </Button>
                    </a>
                  </Link>
                  <Link href={exampleBooks} passHref>
                    <StyledLink>Voorbeelden bekijken</StyledLink>
                  </Link>
                </Actions>
              </Content>
            }
            rightColumn={isMobile ? undefined : <ImageSlider />}
          />
        </Wrapper>
        <BookThemes />
        <Promise />
        <USP />
        <EditorExample />
        <Collaborate />
        <Gift />
        <Example />
        <BookTypes />
        <Reviews />
        <GoodToKnow />
      </StyledMain>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return await ssrGetFeatured.getServerPage({}, ctx);
};

export default Home;
