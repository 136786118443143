import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { fontDark } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Title from '@elements/Title';

import TwoColumn from './TwoColumn';

const Component = styled.div`
  padding: ${space * 5}px 0;
`;

const Content = styled.div`
  color: ${fontDark};
  width: 100%;

  > * {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    ${tablet} {
      max-width: 450px;
      justify-content: left;
      text-align: left;
    }
  }
  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const ImgContainer = styled.div<{ faded: boolean }>`
  width: 100%;
  position: relative;
  img {
    border-radius: 40px;
    transition: opacity 0.5s ease;
    opacity: ${({ faded }) => (faded ? 0 : 1)};
  }
`;

const Promise = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [faded, setFaded] = useState(false);

  const images = [
    'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/marit30-ontvanger_CYLZiHFRz.jpg?updatedAt=1715850065995&ik-s=063b86e6cb0a6768855ced434a014830a48bbf3d',
    'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/jindra80-ontvanger_SH19hy3J1.jpg?updatedAt=1715850065970&ik-s=1be74617eebf24ccd8fd95fd95a6a9a0aecfeeb6',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFaded(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFaded(false);
      }, 500); // Fade 500ms
    }, 8000); // Show image for 8s

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Component>
      <TwoColumn
        alignment="center"
        leftColumn={
          <Content>
            <Title size="xxl" as="h2" className="mb-24">
              Jullie meest waardevolle cadeau voor nu en later
            </Title>
            <Link href={bookChoice} passHref>
              <a>
                <Button iconRight={faArrowRightLong} size="big">
                  Begin nu
                </Button>
              </a>
            </Link>
          </Content>
        }
        rightColumn={
          <ImgContainer faded={faded}>
            <Image
              src={images[currentImageIndex]}
              alt={`Slideshow Image ${currentImageIndex}`}
              width={530}
              height={420}
              layout="responsive"
              loading="lazy"
            />
          </ImgContainer>
        }
      />
    </Component>
  );
};

export default Promise;
