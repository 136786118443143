import Image from 'next/image';
import styled from 'styled-components';

import { backgroundColoredMedium, fontMedium } from '@constants/colors';
import { laptop, tablet } from '@constants/media-queries';
import { containerWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Text from '@elements/Text';
import Title from '@elements/Title';

const Component = styled.div`
  padding: 0 ${space * 3}px;
`;

const Container = styled.div`
  list-style: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${space * 5}px ${space * 3}px;
  gap: ${space * 4}px;
  max-width: ${containerWidth};
  background-color: ${backgroundColoredMedium};
  border-radius: 40px;

  ${laptop} {
    flex-direction: row;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-basis: 33.3333%;
  color: ${fontMedium};
  gap: ${space * 3}px;

  ${laptop} {
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  ${tablet} {
    min-width: 50px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-aling: left;
  flex-basis: 100%;

  ${laptop} {
    text-align: center;
  }
`;

type Props = {
  image: string;
  title: string;
  description: string;
};

const USPItem = ({ image, title, description }: Props) => (
  <Item>
    <ImgContainer>
      <Image
        src={image}
        alt={title}
        width={50}
        height={50}
        layout="intrinsic"
        loading="lazy"
      />
    </ImgContainer>
    <TextContainer>
      <Title size="sm" weight={800} as="h4">
        {title}
      </Title>
      <Text size="nm">{description}</Text>
    </TextContainer>
  </Item>
);

const USP = () => {
  return (
    <Component>
      <Container>
        <USPItem
          image="/images/usp/icon_heart.png"
          title="Samen fotoboeken maken"
          description="Deel de link en werk tegelijk samen"
        />
        <USPItem
          image="/images/usp/icon_sparkle.png"
          title="Hoge kwaliteit"
          description="De beste materialen"
        />
        <USPItem
          image="/images/usp/icon_checkmark.png"
          title="Weinig werk voor jou"
          description="Deelnemers vullen eigen pagina's"
        />
      </Container>
    </Component>
  );
};

export default USP;
